import { xcss } from '@atlaskit/primitives';
import { type FunctionComponent, type ReactNode } from 'react';

import { NextBestActionPressable } from '../next-best-action-button';

const nextBestActionButtonStyles = xcss({
	display: 'flex',
	width: 'fit-content',
	paddingLeft: '0',
	color: 'color.link',
	backgroundColor: 'color.background.brand.subtlest.hovered',
	':hover': {
		textDecoration: 'underline',
	},
});

export const NextBestAction: FunctionComponent<{ ctaMessage: ReactNode }> = ({ ctaMessage }) => {
	return (
		<NextBestActionPressable ctaText={ctaMessage} pressableStyle={nextBestActionButtonStyles} />
	);
};
