import React from 'react';
import { TestPlacement } from '@post-office/placements--growth/confluence-side-nav/test';
import { Placement } from '@post-office/placements/confluence-side-nav';
import { useIntl } from 'react-intl-next';

import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';

export const PostOfficeConfluenceSideNavPlacement = ({
	localDev = false,
}: {
	localDev?: boolean;
}) => {
	const { locale } = useIntl();
	const isEnglishLocale = locale.toLowerCase().startsWith('en');

	if (!fg('post-office-connie-side-nav-killswitch')) {
		return <></>;
	}

	if (localDev) {
		return <TestPlacement />;
	}

	if (
		isEnglishLocale &&
		expValEquals('post-office_expt_confluence-next-best-actions', 'cohort', 'variation')
	) {
		return <Placement />;
	}

	return <></>;
};
