import { Pressable, type XCSS } from '@atlaskit/primitives';
import { type ReactNode } from 'react';

export type BaseButtonProps = {
	ctaText: ReactNode;
};

export type PressableProps = BaseButtonProps & {
	pressableStyle: XCSS;
};

export const NextBestActionPressable = ({ ctaText, pressableStyle }: PressableProps) => {
	// 'TODO: fire analytics event -> https://atlassian.design/components/primitives/pressable/examples#track-events-for-atlassian-internal-services',
	const handleClick = () => {};

	return (
		<Pressable xcss={pressableStyle} onClick={handleClick}>
			{ctaText}
		</Pressable>
	);
};
