import { B50 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { type AdControls } from '@post-office/components--ad-controls';
import { useMessageContext } from '@post-office/message-context';
import { type ComponentProps, type FunctionComponent, type ReactNode } from 'react';

import { Actions } from './components/actions';
import { NextBestAction } from './components/cta';
import { type Nba } from './types';

export type Props = {
	whyAmISeeingThisModalContent?: ComponentProps<typeof AdControls>['whyAmISeeingThisModalContent'];
} & Nba;

const confluenceNbaMessageStyles = css({
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	borderRadius: token('border.radius.200', '8px'),
	backgroundColor: token('color.background.accent.blue.subtlest', B50),
	'@media (max-width: 248px)': {
		display: 'none',
	},
	minWidth: '230px',
	maxWidth: '272px',
	minHeight: '72px',
	padding: `${token('space.150', '12px')} ${token('space.150', '12px')} ${token('space.150', '12px')}${token('space.075', '6px')}`,
	'&:hover': {
		cursor: 'pointer',
		outline: `1px solid ${token('color.background.accent.blue.subtle.hovered')}`,
	},
});

const iconContainerStyles = css({
	display: 'flex',
	position: 'absolute',
	top: '12px',
	width: '40px',
	height: '40px',
});

const bodyContentStyles = css({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: '40px',
	paddingLeft: token('space.100', '8px'),
	width: '100%',
	minWidth: '154px',
	maxWidth: '196px',
});

const headerStyles = css({
	width: '100%',
	font: token('font.heading.xsmall', fontFallback.heading.xsmall),
	paddingBottom: token('space.100', '8px'),
});

const BodyContent: FunctionComponent<{ header: ReactNode; ctaMessage: ReactNode }> = ({
	header,
	ctaMessage,
}) => {
	return (
		<div css={bodyContentStyles}>
			<div role="presentation" css={headerStyles}>
				{header}
			</div>
			<NextBestAction ctaMessage={ctaMessage} />
		</div>
	);
};

export const ConfluenceNbaBannerMessage = ({
	icon,
	header,
	ctaMessage,
	whyAmISeeingThisModalContent,
}: Props) => {
	const { messageInstanceId } = useMessageContext();
	if (!messageInstanceId) {
		throw new Error('messageInstanceId is required');
	}

	return (
		<div data-testid="confluence-next-best-action-message" css={confluenceNbaMessageStyles}>
			<div css={iconContainerStyles}>{icon}</div>
			<BodyContent header={header} ctaMessage={ctaMessage} />
			<Actions whyAmISeeingThisModalContent={whyAmISeeingThisModalContent} />
		</div>
	);
};
